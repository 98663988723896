import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Accolades = ({ className }) => {
  const data = useStaticQuery(graphql`
    {
      bbb: file(relativePath: { eq: "repeating/Accolades/BBB.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 130)
        }
      }
      boulderBar: file(
        relativePath: { eq: "repeating/Accolades/Boulder-Bar.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 120)
        }
      }
      cba: file(relativePath: { eq: "repeating/Accolades/CBA.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 107)
        }
      }
      ctla: file(
        relativePath: {
          eq: "repeating/Accolades/CTLA-colorado-trial-lawyers-association.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 107)
        }
      }
      larimerBar: file(
        relativePath: { eq: "repeating/Accolades/Larimer-Bar.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 101)
        }
      }
      millionDollar: file(
        relativePath: { eq: "repeating/Accolades/Million Dollar.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 127)
        }
      }
      naela: file(
        relativePath: {
          eq: "repeating/Accolades/National-Academy-of-Elder-Law-Attorneys-Inc.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 205)
        }
      }
      superLawyers: file(
        relativePath: { eq: "repeating/Accolades/Super Lawyers.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 231)
        }
      }
      weldCounty: file(
        relativePath: { eq: "repeating/Accolades/weld-county.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 148)
        }
      }
      bestLawyers: file(
        relativePath: { eq: "repeating/Accolades/BestLawyers.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 253)
        }
      }
    }
  `);

  return (
    <section className={`pb-20 md:pb-32 ${className || ""}`}>
      <div className="container">
        <div className="grid grid-cols-2 md:grid-cols-4 text-center gap-4 md:gap-2.5 lg:gap-5 mb-4">
          <div className="bg-black rounded-md px-3 py-2.5 flex items-center justify-center h-[145px]">
            <GatsbyImage image={data.bbb.childImageSharp.gatsbyImageData} />
          </div>
          <div className="bg-black rounded-md px-3 py-2.5 flex items-center justify-center h-[145px]">
            <GatsbyImage
              image={data.superLawyers.childImageSharp.gatsbyImageData}
            />
          </div>
          <div className="bg-black rounded-md px-3 py-2.5 flex items-center justify-center h-[145px]">
            <GatsbyImage
              image={data.bestLawyers.childImageSharp.gatsbyImageData}
            />
          </div>
          <div className="bg-black rounded-md px-3 py-2.5 flex items-center justify-center h-[145px]">
            <GatsbyImage
              image={data.millionDollar.childImageSharp.gatsbyImageData}
            />
          </div>
          
        </div>

        <div className="grid grid-cols-2 md:grid-cols-8 text-center gap-4 md:gap-2.5 lg:gap-5">
          <div className="bg-black rounded-md px-3 py-2.5 flex items-center justify-center h-[145px] md:col-start-2">
           
            <GatsbyImage image={data.naela.childImageSharp.gatsbyImageData} />
          </div>
              <div className="bg-black rounded-md px-3 py-2.5 flex items-center justify-center h-[145px]">
            <GatsbyImage image={data.ctla.childImageSharp.gatsbyImageData} />
          </div>
              <div className="bg-black rounded-md px-3 py-2.5 flex items-center justify-center h-[145px]">
            <GatsbyImage
              image={data.boulderBar.childImageSharp.gatsbyImageData}
            />
          </div>
              <div className="bg-black rounded-md px-3 py-2.5 flex items-center justify-center h-[145px]">
            <GatsbyImage image={data.cba.childImageSharp.gatsbyImageData} />
          </div>
              <div className="bg-black rounded-md px-3 py-2.5 flex items-center justify-center h-[145px]">
            <GatsbyImage
              image={data.larimerBar.childImageSharp.gatsbyImageData}
            />
          </div>
              <div className="bg-black rounded-md px-3 py-2.5 flex items-center justify-center h-[145px]">
          <GatsbyImage
              image={data.weldCounty.childImageSharp.gatsbyImageData}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Accolades;
