import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import ButtonSolid from "../Button/ButtonSolid";
import ButtonGhost from "../Button/ButtonGhost";

const CTA = ({ heading, headingLevel, subtext, className }) => {
  const HeadingTag = headingLevel || "h2";

  const data = useStaticQuery(graphql`
    {
      background: file(
        relativePath: { eq: "repeating/CTA/Background Mountain.png" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <section
      className={`relative pt-18 md:pt-20 pb-20 md:pb-10 ${className || ""}`}
    >
      <div className="absolute top-0 left-0 w-full h-full">
        <GatsbyImage
          image={data.background.childImageSharp.gatsbyImageData}
          className="h-full"
        />
      </div>

      <div className="container relative">
        <div className="flex flex-col md:flex-row justify-between items-center md:items-end md:mb-8">
          <header className="max-w-3xl text-left mb-3 md:mb-0">
            <HeadingTag>{heading || ["Get The Help You Need"]}</HeadingTag>

            <p className="text-gray-50 md:mb-0">
              {subtext ||
                "Get JBP Legal’s experience on your side. Contact us for questions or a consultation."}            </p>
          </header>

          <div className="grid md:flex flex-col w-full md:w-auto space-y-4">
            <ButtonGhost
              href="tel:720-491-3117"
              altStyle={2}
              text="(720) 491-3117"
              className="w-full md:w-auto"
            />
            <ButtonSolid
              modal="modal-contact"
              altStyle={2}
              text="Contact"
              className="w-full md:w-auto"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTA;
