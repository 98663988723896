import React, { useState, useRef } from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";
import Scrollspy from "react-scrollspy";

const StyledScrollNavigation = styled.div`
  .scroll-navigation {
    ul {
      li {
        &.is-current {
          a {
            ${tw`text-primary-50`}
            &:before {
              ${tw`md:w-4`}
            }
          }
        }
        a {
          ${tw`text-primary-600 hover:text-primary-50 no-underline flex items-center`}
          &:before {
            content: "";
            ${tw`w-0 h-0.5 bg-primary-50 mr-2.5 block transition-all duration-300 ease-linear`}
          }
        }
      }
    }
  }
`;

const ScrollNavigation = ({ children, className }) => {
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const scrollNavigationList = useRef(null);

  const clickHandler = () => {
    setActiveState(setActive === "" && "active");
    setHeightState(setActive === "active" ? "0px" : "400px");
  };

  return (
    <StyledScrollNavigation
      className={`${className} ${setActive}`}
      onKeyDown={clickHandler}
      onClick={clickHandler}
    >
      <nav className="scroll-navigation md:sticky lg:top-32">
        <Scrollspy
          items={[
            "anchor-1",
            "anchor-2",
            "anchor-3",
            "anchor-4",
            "anchor-5",
            "anchor-6",
            "anchor-7",
            "anchor-8",
          ]}
          offset={-100}
          currentClassName="is-current"
          ref={scrollNavigationList}
          className="scrollspy flex flex-col space-y-2 !max-h-full transition-all duration-300 ease-linear"
          style={{ maxHeight: `${setHeight}` }}
        >
          {children}
        </Scrollspy>
      </nav>
    </StyledScrollNavigation>
  );
};

export default ScrollNavigation;
