import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";
import Accolades from "../components/Repeating/Accolades";
import CallToAction from "../components/Repeating/CTA";

export const query = graphql`
   {
      openGraphImage: file(relativePath: { eq: "open-graph/facebook/homepage.jpg" }) {
         publicURL
      }
      twitterOpenGraphImage: file(relativePath: { eq: "open-graph/twitter/Homepage.jpg" }) {
         publicURL
      }
      background: file(relativePath: { eq: "repeating/CTA/Background Mountain.png" }) {
         childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
         }
      }
   }
`;

const url = typeof window !== "undefined" ? window.location.pathname : "";

const StyledContent = styled.div`
   ul {
      ${tw`list-disc pl-6 mb-6 flex flex-col space-y-0.5`}
   }
   ol {
      ${tw`list-decimal pl-6 mb-6 flex flex-col space-y-0.5`}
   }
   a {
      ${tw`text-primary-600`}
   }
`;

const Page = ({ data }) => {
   return (
      <Layout>
         <SearchEngineOptimization
            title="Guide For Personal Injury Clients | JBP Legal"
            description="Have you been involved in an accident and are considering a personal injury claim? Here’s everything you need to know about what comes next."
            openGraphImage={data.openGraphImage.publicURL}
            twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
         />
         <section className="relative pt-16 md:pt-28 pb-14 md:pb-22 text-center md:text-left">
            <div className="absolute top-0 left-0 w-full h-full">
               <GatsbyImage image={data.background.childImageSharp.gatsbyImageData} className="h-full" />
            </div>
            <div className="container relative">
               <div className="max-w-[800px]">
                  <h1>Guide For Personal Injury Clients</h1>
                  <p className="mb-0">
                     Have you been in a car accident or other incident resulting from someone else’s carelessness? Here’s everything you need to know
                     about your personal injury claim.
                  </p>
               </div>
            </div>
         </section>
         <section className="pt-16 md:pt-24 pb-20 md:pb-32">
            <div className="container">
               <ScrollWrapper className="grid md:grid-cols-12 md:gap-x-12 lg:gap-x-24">
                  <ScrollNavigation className="hidden md:block md:col-start-1 md:col-span-4">
                     <li className="font-bold text-sm">
                        <AnchorLink to={url + "#anchor-1"} title="Mistakes" stripHash className="text-primary-600 hover:text-primary-50" />
                     </li>
                     <li className="font-bold text-sm">
                        <AnchorLink to={url + "#anchor-2"} title="Attorney" stripHash className="text-primary-600 hover:text-primary-50" />
                     </li>
                     <li className="font-bold text-sm">
                        <AnchorLink to={url + "#anchor-3"} title="Medical" stripHash className="text-primary-600 hover:text-primary-50" />
                     </li>
                     <li className="font-bold text-sm">
                        <AnchorLink to={url + "#anchor-4"} title="Your Claim" stripHash className="text-primary-600 hover:text-primary-50" />
                     </li>
                  </ScrollNavigation>
                  <ScrollContent className="md:col-end-13 md:col-span-8">
                     <StyledContent>
                        <div id="anchor-1" className="mb-16 md:mb-20">
                           <h2>5 Mistakes Clients Typically Make</h2>
                           <p>
                              When it comes to personal injury claims, staying on top of your medical care is paramount. Here are five mistakes to
                              avoid:
                           </p>
                           <ol>
                              <li>Not seeing a doctor when experiencing pain following an accident</li>
                              <li>Not following a doctor’s instructions</li>
                              <li>Not keeping doctor appointments</li>
                              <li>
                                 Failing to inform a doctor about related medical problems you had before the accident (when being treated or examined
                                 by a doctor, don’t make any incorrect statements about any prior injuries or accidents you have in your medical
                                 history)
                              </li>
                              <li>
                                 Talking about the case with people other than your doctors and your attorney (don’t give any written, recorded, or
                                 oral statements to anyone about accidents or injuries without first consulting with your attorney)
                              </li>
                           </ol>
                        </div>

                        <div id="anchor-2" className="mb-16 md:mb-20">
                           <h2>Retaining an Attorney</h2>

                           <h3>First Steps</h3>
                           <p>
                              Retaining us is easy and won’t cost you any money up-front. We’ll obtain general information from you regarding your
                              case and provide you with guidance about what you should and should not do. We’ll then:
                           </p>
                           <ul>
                              <li>
                                 Ask you to sign authorization forms so we can obtain your medical records and any other information that’s pertinent
                                 to your case
                              </li>
                              <li>Notify the responsible party and/or their insurance company that you’ve retained an attorney</li>
                              <li>Request your medical chart and billing information from the doctors and hospitals where you’ve sought care.</li>
                           </ul>

                           <h3>What To Provide Your Attorney</h3>
                           <ul>
                              <li>Photos of the accident and/or scene</li>
                              <li>Photos of vehicle damage (before you get it repaired)</li>
                              <li>Descriptions of medical items you receive from your doctor(s), such as pill bottles, casts, and braces</li>
                              <li>Receipts itemizing any expenses you’ve incurred as a result of the accident</li>
                           </ul>

                           <h3>Communicating With Your Attorney</h3>
                           <ul>
                              <li>Report any change to your address, phone number, or employment</li>
                              <li>Report any changes within your job, such as your duties, salary, or anything else that’s work-related</li>
                              <li>Provide information about any extensive medical treatment or hospitalizations</li>
                              <li>Provide any new information that might have a bearing on the case</li>
                           </ul>
                        </div>

                        <div id="anchor-3" className="mb-16 md:mb-20">
                           <h2>Handling Your Medical Care</h2>
                           <h3>Communicating With Your Doctor</h3>
                           <p>
                              It’s important to follow your doctor’s advice and make all of your appointments. Missing appointments delay your
                              recovery and can harm your claim.
                           </p>
                           <p>
                              Make sure your doctor has all of the information they need regarding your condition and whether you continue to be in
                              pain. Your doctor will record entries about your medical status during each visit.
                           </p>
                           <p>
                              When you retain us as your attorney, we aim to recover compensation for the pain and suffering you’ve endured as a
                              result of an accident. If you’re in pain or can’t work but don’t report those things to your doctor, the insurance
                              company, and, ultimately, the jury may not believe you when you tell them those things after the fact. So, be open and
                              straightforward in your communications with your medical treatment providers.
                           </p>

                           <h3>How Your Medical Bills Will Be Covered</h3>
                           <p>
                              Your attorney will try to arrange to have your medical bills paid by your insurance company while your case is pending.
                              For example, you might have a medical payments provision as part of your automobile insurance policy. Workers’
                              compensation insurance may also cover the bills (if applicable).
                           </p>
                           <p>
                              You’ll want to send all of your medical bills directly to your attorney so they can make sure the correct insurance
                              company receives them. You will still be responsible for co-pays as your treatment progresses. However, we will recover
                              them for you later from the at-fault party’s insurer as a component of your damages.
                           </p>

                           <h4>Medical Lien (When There’s No Insurance)</h4>
                           <p>
                              Even if you don’t have health insurance, you can get the care you need for your injuries. But your doctor will expect to
                              be paid at the conclusion of your case. In these instances, doctors typically require written confirmation that they’ll
                              be paid from the proceeds you receive. Colorado law allows doctors to render treatment on a lien against the proceeds of
                              your case. If you’re asked to sign a ‘lien letter,’ contact your attorney first so they can evaluate the agreement.
                           </p>

                           <h4>Subrogation</h4>
                           <p>
                              Subrogation is where your insurance company pays for the expenses arising from your injury then attempts to recoup some
                              or all of the money it paid from the liable party’s insurer. Your insurance company is typically required to pay its
                              share of the attorney’s fees and costs involved in recovering those funds. It’s handled on a case-by-case basis,
                              however, and federal law may limit our ability to reduce subrogation interests of self-funded health plans. Either way,
                              we’re in your corner and will work with you to maximize your recovery.
                           </p>
                        </div>

                        <div id="anchor-4">
                           <h2>What You Should Know About Your Claim</h2>

                           <h3>You May Be Watched (And Filmed) By Investigators</h3>
                           <p>
                              Insurance companies typically conduct detailed investigations for personal injury claims. Investigators will look into
                              your background and may surveil your home and record your activities. You might be photographed or recorded on video.
                           </p>
                           <p>
                              Investigators are looking for indications that you’re not injured or not hurt as badly as you claim. They may be looking
                              to see if you’re lifting heavy objects, for example, or otherwise engaging in strenuous physical activity. Despite those
                              efforts, it’s important to note that in many cases, photographs and videos taken during surveillance have proved useful
                              in corroborating a client’s claim because they actually demonstrate the client’s injury.
                           </p>
                           <p>
                              If you believe you’re under surveillance, contact your attorney. Try to avoid the camera if possible, and never
                              exaggerate your limitations or otherwise pose for the lens.
                           </p>

                           <h3>Bankruptcy Can Impact Your Personal Injury Case</h3>
                           <p>
                              If you’re considering filing bankruptcy, it’s important to know that you might lose all your rights in your personal
                              injury case. A bankruptcy court has the power to take over your case, settle it, and give the proceeds to your
                              creditors. Contact your attorney before you file to ensure you understand the ramifications.
                           </p>

                           <h3>How Your Case’s Value Is Determined</h3>
                           <p>Every case is unique—there’s no formula that will provide a specific value for your case.</p>
                           <p>
                              For serious injuries, the value that’s ultimately determined is typically based on the amount of insurance coverage
                              available, the nature and extent of your injuries (plus duration), and an assessment of liability. It’s your attorney’s
                              duty to you to seek fair and just compensation for your injuries.
                           </p>
                           <p>Colorado law allows recovery based on the following criteria:</p>
                           <ul>
                              <li>The nature and extent of the injury</li>
                              <li>Whether the injury is permanent</li>
                              <li>The amount of disability</li>
                              <li>Medical expenses (both already incurred and future expenses)</li>
                              <li>Mileage to and from a doctor or hospital</li>
                              <li>Wage loss (past and future)</li>
                              <li>Property damage (including your vehicle and other possessions)</li>
                              <li>Pain and suffering</li>
                              <li>Loss of consortium for your spouse (the loss of love, companionship, comfort, etc.)</li>
                           </ul>

                           <h3>Be Patient—Claims Can Take Time</h3>
                           <p>
                              While we like to get involved early and assist our clients on the liability issues that confront them, your case may
                              take months or years to resolve. Completing your treatment for accident-related injuries is usually the first step
                              (unless your damages clearly exceed the available insurance policy limits.
                           </p>
                           <p>
                              To proceed with your claim, we have to first obtain the reports from your doctors that describe your medical condition
                              and prognosis. If we try to settle before we have that information, you could lose money that you’re entitled to
                              recover. It can take doctors some time to provide this information to us, and we may be forced to make repeated
                              requests.
                           </p>
                           <p>
                              Patience will be required. We will work diligently to resolve your case as quickly as possible while ensuring that full
                              value is obtained.
                           </p>

                           <h3>Will I Need to File a Lawsuit?</h3>
                           <p>
                              Sometimes to obtain fair recovery, a lawsuit will, unfortunately, be necessary. This is a legal decision that we will
                              help you make. Settlement is always a possibility, and it’s important to note that only a small percentage of lawsuits
                              that are filed ultimately go to trial.
                           </p>

                           <h3>What About Mediation?</h3>
                           <p>
                              Sometimes parties in a dispute will agree on mediation. They’ll meet with an independent third person (usually an
                              experienced lawyer or retired judge) who can help them arrive at a settlement. It’s an informal, non-binding process
                              that’s less expensive than a trial but may provide valuable insights as to the strengths and weaknesses in a case and
                              what settlement opportunities are available.
                           </p>

                           <h3>What If I Was Hit by an Uninsured or Underinsured Driver?</h3>
                           <p>
                              You might still be eligible for benefits under your auto policy, a resident relative’s policy, or the policy covering
                              the vehicle you were in when the accident occurred. Auto policies typically have a provision for uninsured motorists. In
                              most cases, you’ll be able to collect lost wages, money for medical bills, and compensation for pain and suffering just
                              like you would from the at-fault driver’s policy. Importantly, Colorado law prohibits insurance companies from raising
                              rates when you pursue an uninsured or underinsured motorist claim because the accident was not your fault.
                           </p>

                           <h3>What About Claims Against the Government?</h3>
                           <p>
                              There are special rules involved in a vehicle accident or other event involving the government (such as a state, city,
                              county, local government, or the U.S. government). It’s important to file a Notice of Claim with the appropriate
                              government agency as soon as possible following your injury. Contact your attorney immediately if you believe the
                              government is involved in your case.
                           </p>
                        </div>
                     </StyledContent>
                  </ScrollContent>
               </ScrollWrapper>
            </div>
         </section>

         <Accolades />
         <CallToAction />
      </Layout>
   );
};

export default Page;
